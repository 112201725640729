<template>
    <div
        v-if="hasDND"
        class="flex bg-yellow-100 rounded-lg p-2 text-sm leading-5 font-normal text-gray-800 items-center gap-x-2 w-full"
    >
        <DNDIcon class="w-4 h-4"></DNDIcon>
        <p>
            <span v-if="recipients.length > 1">Some leads have</span>
            <span v-else-if="showLeadName"
                ><b>{{ recipients[0].fullName || `${recipients[0].firstName} ${recipients[0].lastName}` }}</b> has</span
            >
            Do Not Disturb enabled
            <span v-if="recipients.length === 1"
                >until <b>{{ formatHumanReadableDate(new Date(recipients[0].doNotDisturb?.endDate), false) }}</b></span
            >
        </p>
    </div>
</template>
<script setup lang="ts">
import { defineProps, computed } from "vue"
import { formatHumanReadableDate } from "@/shared/utils/helpers"
import DNDIcon from "@/components/icons/DNDIcon.vue"
import { Lead } from "@/shared/models/lead"
import { InboxContact } from "@/shared/models/inbox"

interface Props {
    recipients: Lead[] | InboxContact[]
    showLeadName?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    showLeadName: false,
})

const hasDND = computed(() =>
    props.recipients.some(
        (recipient) =>
            recipient.doNotDisturb?.isEnabled &&
            new Date() >= new Date(recipient.doNotDisturb.startDate as Date) &&
            new Date() <= new Date(recipient.doNotDisturb.endDate as Date)
    )
)
</script>
