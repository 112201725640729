<template>
    <div :class="[props.class, getWrapperStyleInline]">
        <div class="flex">
            <p v-if="label" :class="[getLabelStyleInline, 'text-sm font-medium text-gray-700']">{{ label }}</p>
            <span v-if="required" :class="starStyle">*</span>
        </div>
        <p v-if="description" class="text-sm text-gray-500 my-4">
            {{ description }}
        </p>
        <Dropdown
            v-model="localModelValue"
            :options="localOptions"
            :showClear="isShowClear"
            :optionLabel="optionLabel"
            :optionValue="optionValue"
            :placeholder="placeholder"
            :class="[errorStyle, props.class, inputStyle, getInputStyleInline]"
            :panelClass="panelClass"
            :disabled="disabled"
            :emptyMessage="emptyMessage"
            :appendTo="appendTo"
            :editable="isEditable"
            @focus="disableValidationError"
            @update:model-value="filterOptions"
        >
            <template v-if="!optionValue" #value="slotProps">
                <div v-if="!isShow">
                    <slot name="customLabel" :slotProps="slotProps"></slot>
                </div>
                <div v-else class="flex items-center space-x-2">
                    <EyeIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                    <span class="text-gray-700">Show: {{ localModelValue }}</span>
                </div>
            </template>
            <template #option="slotProps">
                <component :class="slotProps.option.class" class="mr-2 w-5 h-5" :is="slotProps.option.icon" />
                <span>{{ slotProps.option.label }}</span></template
            >
        </Dropdown>
        <p v-if="localErrorMessage" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import { EyeIcon } from "@heroicons/vue/outline"

interface Props {
    modelValue?: any
    value?: any
    options: any[]
    label?: string
    description?: string
    optionLabel?: string
    optionValue?: string
    placeholder?: string
    isShowClear?: boolean
    class?: string
    panelClass?: string
    errorMessage?: string
    invalid?: boolean
    disabled?: boolean
    emptyMessage?: string
    small?: boolean
    inline?: boolean
    appendTo?: string
    required?: boolean
    isEditable?: boolean
    isShow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isShowClear: false,
    placeholder: "Select Element",
    disabled: false,
    required: false,
    invalid: false,
    isEditable: false,
    isShow: false,
})

const emit = defineEmits(["update:modelValue", "onChange"])

const localModelValue = computed({
    get: () => props.modelValue || props.value,
    set: (value) => {
        emit("update:modelValue", value)
        emit("onChange", value)
    },
})

const localOptions = ref(props.options)

const localErrorMessage = ref(props.errorMessage)
const localInvalid = ref(props.invalid)
const errorStyle = computed(() =>
    localInvalid.value ? "border-red-300 placeholder-red-600 ring-1 ring-red-600 border-red-600" : ""
)
const starStyle = computed(() => [props.invalid ? "text-red-600" : "text-gray-700", "pl-1"])

const inputStyle = computed(() => (props.small ? "h-[38px] text-sm" : ""))
const getWrapperStyleInline = computed(() => (props.inline ? "flex items-center gap-4" : ""))
const getLabelStyleInline = computed(() => (props.inline ? "w-2/5" : "mb-1"))
const getInputStyleInline = computed(() => (props.inline ? "w-80" : ""))

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
}

function filterOptions(model: string | number) {
    const { optionValue } = props
    if (typeof model === "number" || typeof model === "object") {
        return
    }

    const lowerCasedModel = model?.toLowerCase()

    if (lowerCasedModel && props.isEditable) {
        localOptions.value = props.options.filter((option) =>
            optionValue
                ? option[optionValue].toLowerCase().includes(lowerCasedModel)
                : option.toLowerCase().includes(lowerCasedModel)
        )
        return
    }
    localOptions.value = props.options
}

function getIcon(value: any) {
    return localOptions.value.find((option) => option.value === value)?.icon
}

function getLabel(value: any) {
    return localOptions.value.find((option) => option.value === value)?.label
}

function getClass(value: any) {
    return localOptions.value.find((option) => option.value === value)?.class
}

onMounted(() => {
    disableValidationError()
})

watch(
    () => props.errorMessage,
    () => {
        localErrorMessage.value = props.errorMessage
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.invalid,
    () => (localInvalid.value = props.invalid)
)

watch(
    () => props.options,
    () => {
        localOptions.value = props.options
    }
)
</script>

<style scoped>
:deep(.p-dropdown.error-style) {
    @apply border-red-300;
}

:deep(.p-dropdown.error-style .p-dropdown-label.p-placeholder) {
    @apply text-red-300;
}
:deep(.p-dropdown.error-style .p-dropdown-trigger) {
    @apply text-red-900;
}
</style>
