<template>
    <GgmsInput
        input-class="bg-gray-100 border-0"
        v-model="searchTerm"
        :small="true"
        :iconEnd="SearchIcon"
        placeholder="Search"
    ></GgmsInput>
    <div class="flex flex-col mt-4 gap-y-4">
        <div v-for="category in eventsCategories" :key="category.title" class="flex flex-col">
            <div
                class="w-full bg-primary-color-50 text-primary-color-800 text-xs leading-4 font-semibold uppercase py-1 px-2 mb-2 rounded-md"
            >
                {{ category.title }}
            </div>
            <div
                v-for="event in category.events"
                :key="event"
                class="text-gray-900 text-base leading-6 font-medium p-2.5 cursor-pointer"
                @click="chooseEvent(event)"
            >
                {{ convertTriggerEvent(event) }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue"
import { SearchIcon } from "@heroicons/vue/outline"
import GgmsInput from "@/components/GgmsInput.vue"
import TriggerNode from "@/components/workflows/TriggerNode.vue"
import MembershipChangedTriggerSidebar from "@/components/workflows/sidebar/trigger/MembershipChangedTriggerSidebar.vue"
import PropertyValueChangeTriggerSidebar from "@/components/workflows/sidebar/trigger/PropertyValueChangeTriggerSidebar.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { convertTriggerEvent } from "@/shared/utils/helpers"
import { ContactActivityTriggerNodeType, TriggerNodeType } from "@/shared/models/workflow"
import SubmissionAndViewSidebar from "./SubmissionAndViewSidebar.vue"
import EnrollAndUnenrollSidebar from "./EnrollAndUnenrollSidebar.vue"

const workflowStore = useWorkflowStore()

const searchTerm = ref()
const eventsCategories = [
    {
        title: "call",
        events: [ContactActivityTriggerNodeType.callStarted, ContactActivityTriggerNodeType.callEnded],
    },
    {
        title: "lead",
        events: [ContactActivityTriggerNodeType.propertyValueChanged, ContactActivityTriggerNodeType.leadCreated],
    },
    {
        title: "form",
        events: [ContactActivityTriggerNodeType.submission, ContactActivityTriggerNodeType.view],
    },
    { title: "list", events: [ContactActivityTriggerNodeType.membershipChanged] },
    {
        title: "workflow",
        events: [
            ContactActivityTriggerNodeType.enrolledInWorkflow,
            ContactActivityTriggerNodeType.unenrollFromWorkflow,
        ],
    },
]
interface Props {
    modelValue: boolean
}
defineProps<Props>()

const emit = defineEmits(["update:modelValue", "changeAction"])

function chooseEvent(event: string) {
    workflowStore.modelValue = event
    switch (event) {
        case ContactActivityTriggerNodeType.membershipChanged:
            emit("changeAction", { componentName: MembershipChangedTriggerSidebar.__name })
            break
        case ContactActivityTriggerNodeType.propertyValueChanged:
            emit("changeAction", { componentName: PropertyValueChangeTriggerSidebar.__name })
            break
        case ContactActivityTriggerNodeType.submission:
            workflowStore.isSubmission = true
            emit("changeAction", { componentName: SubmissionAndViewSidebar.__name })
            break
        case ContactActivityTriggerNodeType.view:
            workflowStore.isSubmission = false
            emit("changeAction", { componentName: SubmissionAndViewSidebar.__name })
            break
        case ContactActivityTriggerNodeType.enrolledInWorkflow:
            workflowStore.isEnrollWorkflow = true
            emit("changeAction", { componentName: EnrollAndUnenrollSidebar.__name })
            break
        case ContactActivityTriggerNodeType.unenrollFromWorkflow:
            workflowStore.isEnrollWorkflow = false
            emit("changeAction", { componentName: EnrollAndUnenrollSidebar.__name })
            break
        default:
            emit("changeAction", { componentName: TriggerNode.__name, node: TriggerNodeType.contactActivity })
            break
    }
    workflowStore.setHeaderText()
}
</script>
