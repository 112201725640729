import { AuthUserRole, User } from "@/shared/models/user"
import parsePhoneNumber, { parsePhoneNumberFromString } from "libphonenumber-js"
import { GgmsError } from "@/shared/services/client"
import { Prototype, Theme } from "@/shared/models/config"
import { Category } from "@/shared/models/category"
import { Attribute } from "@/shared/models/attributes"
import { Task } from "@/shared/models/task"
import { Note } from "@/shared/models/note"
import { UploadItem } from "@/shared/models/upload-file"
import { ScheduledMessageItem } from "../models/message-item"
import { Timeline, TimelineItem } from "../models/timelineItem"
import Mustache from "mustache"
import { Address, Agency } from "../models/agency"
import { Lead } from "../models/lead"
import { Agent } from "../models/agent"
import { ScheduledEmailItem } from "../models/email"
import { AgencyTheme, ThemeColors } from "../models/agency-theme"
import { MessageTheme } from "../models/message-theme"
import { Permission, PermissionType, ResourceTypeName, Role } from "../models/role"
import { twMerge } from "tailwind-merge"
import { Contact } from "../models/contact"
import timezones from "@/assets/timezones.json"
import { ContactActivityTriggerNodeType, SendEmailToType } from "../models/workflow"

export function stripHtml(html: string) {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
}

export async function handleLoginResponse(token: string, user: User) {
    setCookie("authToken", token, true)
    setCookie("user", JSON.stringify(user), true)
}

export function isToday(date: Date) {
    const today = new Date()
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    )
}

export function isTomorrow(date: Date) {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return (
        date.getDate() === tomorrow.getDate() &&
        date.getMonth() === tomorrow.getMonth() &&
        date.getFullYear() === tomorrow.getFullYear()
    )
}

export function isThisWeek(date: Date) {
    // This function now considers the week to start on Monday 00:00 and end on Sunday 23:59 (inclusive).
    const today = new Date()

    const nextSunday = new Date()
    nextSunday.setDate(today.getDate() + ((7 - today.getDay()) % 7))
    nextSunday.setHours(23, 59, 59, 999)

    const lastSunday = new Date()
    const adjustedDayIndex = (today.getDay() === 0 ? 7 : today.getDay()) - 1
    lastSunday.setDate(today.getDate() - adjustedDayIndex)
    lastSunday.setHours(0, 0, 0, 0)

    return date.getTime() > lastSunday.getTime() && date.getTime() < nextSunday.getTime()
}

export function getDayOfWeek(date: Date) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return days[date.getDay()]
}
export function formatCallSenderReceiverData(from: Contact, to: Contact[]) {
    const fromDisplayName = getDisplayName(from)
    const fromContactInfo = getContactInfo(from)

    const toDisplayName = getDisplayName(to[0])
    const toContactInfo = getContactInfo(to[0])

    const senderInfo = fromDisplayName ? `${fromDisplayName} (${fromContactInfo})` : fromContactInfo
    const receiverInfo = toDisplayName ? `${toDisplayName} (${toContactInfo})` : toContactInfo

    return `${senderInfo} > ${receiverInfo}`
}

export function formatMessageSenderReceiverData(from: Contact, to: Contact[]) {
    const fromDisplayName = getDisplayName(from)
    const fromContactInfo = getContactInfo(from)

    const toDisplayName = getDisplayName(to[0])
    const toContactInfo = getContactInfo(to[0])

    const senderInfo = fromDisplayName ? `${fromDisplayName} (${fromContactInfo})` : fromContactInfo
    const receiverInfo = toDisplayName ? `${toDisplayName} (${toContactInfo})` : toContactInfo

    return `${senderInfo} > ${receiverInfo}`
}

export function getContactData(item: any, user: User) {
    const to = item.to?.[0]
    const from = item.from
    const SystemEmail = "development@ggms.com"

    if (to?._id === user._id && from?.email.emailAddress !== SystemEmail) {
        return {
            ...(item.from?.firstName && { firstName: item.from?.firstName }),
            ...(item.from?.lastName && { lastName: item.from?.lastName }),
            ...(item.from?.fullName && { fullName: item.from?.fullName }),
            _id: item.from?._id,
            phone: item.from?.phone,
            email: item.from?.email,
        }
    } else {
        return {
            ...(item.to?.[0]?.firstName && { firstName: item.to?.[0]?.firstName }),
            ...(item.to?.[0]?.lastName && { lastName: item.to?.[0]?.lastName }),
            ...(item.to?.[0]?.fullName && { fullName: item.to?.[0]?.fullName }),
            _id: item.to?.[0]?._id,
            phone: item.to?.[0]?.phone,
            email: item.to?.[0]?.email,
        }
    }
}

export function getDisplayName(contact: Contact | undefined): string | undefined {
    if (contact) {
        const { firstName, lastName, fullName } = contact

        if (firstName && lastName) {
            return `${firstName} ${lastName}`
        } else {
            return firstName || lastName || fullName
        }
    }
    return undefined
}

function getContactInfo(contact: Contact): string {
    if (contact.phone && contact.phone.number) {
        return contact.phone.number
    } else if (contact.email && contact.email.emailAddress) {
        return contact.email.emailAddress
    } else if (contact?.number) {
        return contact?.number
    } else {
        return "No contact information available"
    }
}

export function formatDateToIso(value: string | Date) {
    const agencyTimezoneValue = JSON.parse(getCookieValue("selectedAgency") || "{}").timezone
    const timezoneData = timezones.find((tz) => tz.text === agencyTimezoneValue)
    const agencyTimezone = timezoneData ? timezoneData.utc[0] : agencyTimezoneValue
    const date = new Date(value)
    const dateInAgencyTimezone = new Date(date.toLocaleString("en-US", { timeZone: agencyTimezone }))

    const year = dateInAgencyTimezone.getFullYear()
    const month = String(dateInAgencyTimezone.getMonth() + 1).padStart(2, "0")
    const day = String(dateInAgencyTimezone.getDate()).padStart(2, "0")
    const hours = String(dateInAgencyTimezone.getHours()).padStart(2, "0")
    const minutes = String(dateInAgencyTimezone.getMinutes()).padStart(2, "0")

    return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`
}

export function formatDateToUtc(value: string | Date) {
    const agencyTimezoneValue = JSON.parse(getCookieValue("selectedAgency") || "{}").timezone
    const timezoneData = timezones.find((tz) => tz.text === agencyTimezoneValue)
    const agencyTimezoneOffset = timezoneData ? timezoneData.offset : 0

    let date
    if (typeof value === "string" && value.endsWith("Z")) {
        // Parse the date string manually
        const [datePart, timePart] = value.split("T")
        const [year, month, day] = datePart.split("-").map(Number)
        const [hour, minute] = timePart.split(":").map(Number)
        date = new Date(year, month - 1, day, hour - agencyTimezoneOffset, minute)
    } else {
        date = new Date(value)
        // Subtract the timezone offset from the date's hours
        date.setHours(date.getHours() - agencyTimezoneOffset)
    }

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`
}

export function formatHumanReadableDate(date: Date, isWithTime = true) {
    const agencyTimezone = JSON.parse(getCookieValue("selectedAgency") || "{}")?.timezone || "UTC"
    const timezoneMapping = timezones.find((timezone) => timezone.text === agencyTimezone)?.utc[0]
    const dateInTimezone = new Date(date.toLocaleString("en-US", { timeZone: timezoneMapping }))

    const hours = dateInTimezone.getHours() > 9 ? dateInTimezone.getHours() : `0${dateInTimezone.getHours()}`
    const minutes = dateInTimezone.getMinutes() > 9 ? dateInTimezone.getMinutes() : `0${dateInTimezone.getMinutes()}`
    if (isToday(dateInTimezone)) {
        if (isWithTime) return `Today ${hours}:${minutes}`
        return `Today`
    }
    if (isTomorrow(dateInTimezone)) {
        if (isWithTime) return `Tomorrow ${hours}:${minutes}`
        return `Tomorrow`
    }
    if (isThisWeek(dateInTimezone)) {
        if (isWithTime) return `${getDayOfWeek(dateInTimezone)} ${hours}:${minutes}`
        return `${getDayOfWeek(dateInTimezone)}`
    }
    if (isWithTime) {
        return `${
            dateInTimezone.getMonth() + 1
        }/${dateInTimezone.getDate()}/${dateInTimezone.getFullYear()} ${hours}:${minutes}`
    }
    return `${dateInTimezone.getMonth() + 1}/${dateInTimezone.getDate()}/${dateInTimezone.getFullYear()}`
}

export function clearStorage() {
    sessionStorage.clear()
    localStorage.clear()
    deleteAllCookies()
}

export function getUser(): User {
    const userJson = getCookieValue("user")
    return userJson ? JSON.parse(userJson) : null
}

export function getUserRole() {
    const user = getUser()
    return user?.role?.name || AuthUserRole.member
}

export function getUserPermission(role: Role, resource: ResourceTypeName): Permission {
    return (
        role?.permissions?.find((p: Permission) => [resource, "*"].includes(p.resource)) ||
        (role?.permissions?.[0] as Permission)
    )
}

export function getPermissionMask(permission: PermissionType): number {
    if (permission === PermissionType.READ) {
        return 0b100
    }
    if (permission === PermissionType.WRITE) {
        return 0b010
    }
    return 0b001
}

export function hasUserPermission(role: Role, resource: ResourceTypeName) {
    const userPermission = getUserPermission(role, resource)
    const mask = getPermissionMask(role?.permissions?.[0]?.type || PermissionType.READ)

    return (userPermission?.type & mask) === mask
}

export function hasUserOnlyReadPermission(pathName: string) {
    const user = getUser()
    if (!user?.role) return false

    const resource = modifyResourceName(pathName)?.toLowerCase() as ResourceTypeName
    const userPermission = getUserPermission(user?.role, resource)
    return getPermissionMask(userPermission?.type) === PermissionType.READ
}

export function modifyResourceName(resource: string) {
    if (resource === "people") {
        return "lead"
    }
    if (resource === "tasks") {
        return "task"
    }
    if (resource === "developers") {
        return "apiKey"
    }
    return resource
}

export function updateUser(user: Partial<User>) {
    const currentUser = getUser() ?? {}

    if (getCookieValue("user")) {
        setCookie("user", JSON.stringify({ ...currentUser, ...user }), true)
    }
}

export function getToken() {
    return getCookieValue("authToken")
}

export function isUserLoggedIn(): boolean {
    return !!getToken() && !!getUser()
}

export function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number
): (...args: Params) => void {
    let timer: ReturnType<typeof setTimeout>
    return (...args: Params) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(...args)
        }, timeout)
    }
}

export function wordToKeys(word: string) {
    const keyPads = [
        {
            alpha: ["A", "B", "C"],
            digit: 2,
        },
        {
            alpha: ["D", "E", "F"],
            digit: 3,
        },
        {
            alpha: ["G", "H", "I"],
            digit: 4,
        },
        {
            alpha: ["J", "K", "L"],
            digit: 5,
        },
        {
            alpha: ["M", "N", "O"],
            digit: 6,
        },
        {
            alpha: ["P", "Q", "R", "S"],
            digit: 7,
        },
        {
            alpha: ["T", "U", "V"],
            digit: 8,
        },
        {
            alpha: ["W", "X", "Y", "Z"],
            digit: 9,
        },
        {
            alpha: [" "],
            digit: 0,
        },
    ]
    return word
        .trim()
        .toUpperCase()
        .split("")
        .map((letter) => {
            if (!isNaN(parseInt(letter))) return letter
            const periodWord = keyPads.find((item) => item.alpha.includes(letter))
            if (!periodWord) {
                return ""
            }
            return periodWord.digit
        })
        .join("")
}

export function timeSince(date: Date) {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000)

    let interval = seconds / 31536000

    if (interval > 1) {
        return Math.floor(interval) + " years"
    }
    interval = seconds / 2592000
    if (interval > 1) {
        return Math.floor(interval) + " months"
    }
    interval = seconds / 86400
    if (interval > 1) {
        return Math.floor(interval) + " days"
    }
    interval = seconds / 3600
    if (interval > 1) {
        return Math.floor(interval) + " hours"
    }
    interval = seconds / 60
    if (interval > 1) {
        return Math.floor(interval) + " minutes"
    }
    if (seconds <= 0) {
        return "Now"
    }
    return Math.floor(seconds) + " seconds"
}

export function internationalPhoneFormat(phoneString?: string) {
    if (!phoneString) return phoneString
    return parsePhoneNumber(phoneString)?.formatInternational() || phoneString
}

export function isInvalid(input: string, error: GgmsError) {
    return error?.field === input
}

export function hasErrorMessage(input: string, error: GgmsError) {
    return error?.field === input ? error?.message : undefined
}

export function formatTaskDate(date: Date, isWithTime = true) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const year = date.getFullYear()
    const month = months[date.getMonth()]
    const day = date.getDate()
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`

    return isWithTime ? `${month} ${day}, ${year} ${hours}:${minutes}` : `${month} ${day}, ${year}`
}

export function capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isString(variable: any) {
    return typeof variable === "string"
}

export function deleteEmptyKeys(obj: any) {
    const tempObj = Object.assign({}, obj)
    for (const key in tempObj) {
        if (obj[key] === "") delete tempObj[key]
    }
    return tempObj
}

export function getDateInputFormat(date: Date) {
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
}

export function getTimeInputFormat(date: Date) {
    return `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`
}

export function getInitials(name?: string): string {
    if (!name) return "XY"
    const splittedName = name.split(" ")
    return `${splittedName[0]?.[0] || ""}${splittedName[1]?.[0] || ""}`.toUpperCase()
}

export function getFullName(firstName?: string, lastName?: string, fullName?: string, fallbackEmail?: string): string {
    if (firstName && lastName) return `${firstName} ${lastName}`
    if (firstName) return firstName
    if (lastName) return lastName
    return fullName || fallbackEmail || "Unknown"
}

export function getFullNameWithoutUnknown(firstName?: string, lastName?: string, fullName?: string): string {
    if (firstName && lastName) return `${firstName} ${lastName}`
    if (firstName) return firstName
    if (lastName) return lastName
    return fullName || ""
}

export function formatPhoneNumber(phoneNumber: string) {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber)

    if (parsedPhoneNumber) {
        return parsedPhoneNumber.formatInternational()
    }

    return null
}

export function isAdmin(): boolean {
    return getUserRole() === AuthUserRole.owner
}

export function getCopyArray(data: Array<any> | undefined) {
    return data?.slice() || []
}

export function objectsEqual(o1: any, o2: any): boolean {
    if (o1 === undefined && o2 === undefined) return true
    if ((o1 === undefined && o2 !== undefined) || (o1 !== undefined && o2 === undefined)) return false

    const o1Keys = Object.keys(o1)
    const o2Keys = Object.keys(o2)

    return typeof o1 === "object" && o1Keys.length
        ? o1Keys.length === o2Keys.length && o1Keys.every((p) => objectsEqual(o1[p], o2[p]))
        : o1 === o2
}

export function isArrayOfObjectsEqual(a1: Array<any>, a2: Array<any>) {
    a1 = a1 || []
    a2 = a2 || []

    return a1?.length === a2?.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
}

export function setDragCursor(value: boolean) {
    const html = document.getElementsByTagName("html").item(0)
    if (!html) return
    html.classList.toggle("grabbing", value)
}

export function hasOwn(object: Record<string, any>, property: string): boolean {
    return Object.prototype.hasOwnProperty.call(object, property)
}

export function getAttributesByCategories(
    prototype: Prototype,
    excludedAttributes?: string[],
    allowHidden?: boolean
): Partial<Category>[] {
    const allowedTypes = ["string", "date", "number", "object", "email[]", "phonenumber[]", "eventtime", "tag[]"]
    const res = [] as Partial<Category>[]
    const custom = { attributes: [] as Attribute[], displayName: "Custom Fields" }

    prototype?.attributes.forEach((attribute) => {
        if (!allowHidden && attribute.isHidden) return
        if (attribute.name && excludedAttributes?.includes(attribute.name)) return
        if (!allowedTypes.includes(attribute?.type.toLowerCase())) return

        if (attribute?.category?.name) {
            const index = res.findIndex((category) => category.name === attribute.category?.name)
            if (index === -1) {
                res.push({ ...attribute?.category, attributes: [attribute] })
            } else {
                res[index].attributes?.push(attribute)
            }
        } else {
            custom.attributes.push(attribute)
        }
    })
    res.push(custom)
    return res
}

export function isPinnedItem(elem: TimelineItem) {
    return (elem as TimelineItem)?.pinned !== undefined
}

export function isNote(elem: Timeline): elem is Note {
    return (elem as Note).message !== undefined
}

export function isTask(elem: Timeline): elem is Task {
    return (elem as Task).status !== undefined
}

export function isNoteFile(elem: Timeline): elem is UploadItem {
    return (elem as UploadItem)._id !== undefined
}

export function isLead(elem: Timeline): elem is Lead {
    return (elem as Lead).firstName !== undefined
}

export function setTomoroowMorning() {
    const now = new Date()
    now.setDate(now.getDate() + 1)
    now.setHours(8)
    now.setMinutes(0)
    now.setSeconds(0)
    return now
}

export function setThisAfternoon(scheduleOptions: { text: string; date: Date }[]) {
    const now = new Date()
    const afternoonDate = new Date()
    afternoonDate.setHours(13)
    afternoonDate.setMinutes(0)
    afternoonDate.setSeconds(0)

    if (afternoonDate.getTime() > now.getTime() && scheduleOptions.length < 2) {
        return {
            text: "This afternoon",
            date: afternoonDate,
        }
    }
}

export function isScheduledMessage(elem: Partial<ScheduledMessageItem>): elem is ScheduledMessageItem {
    return (
        (elem as ScheduledMessageItem).__t === "SMSMessagingThread" &&
        (elem as ScheduledMessageItem).scheduledAt !== undefined
    )
}

export function isScheduledEmail(elem: Partial<ScheduledEmailItem>): elem is ScheduledEmailItem {
    return (
        (elem as ScheduledEmailItem).__t === "EmailMessagingThread" &&
        (elem as ScheduledEmailItem).scheduledAt !== undefined
    )
}

export function createNameAndPhoneFormat(name: string, phone: { number: string }) {
    return `${name} ${internationalPhoneFormat(phone.number)}`
}

// split capitalized word to words
export function splitCamelCase(str: string) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2")
}

export function isPrimitiveArrayEqual(array1: any, array2: any) {
    if (array1.length === array2.length) {
        return array1.every((element: string | number) => array2.includes(element))
    }
    return false
}

// It transforms the template variables to the actual values
export function renderTemplate(
    body: string,
    prototypes: Prototype[],
    models: { lead: Lead; agency: Agency; agent: Agent },
    theme?: string,
    signature?: string
) {
    const view: { [key: string]: any } = {}

    prototypes
        .filter((prototype) => ["lead", "agency", "agent"].includes(prototype.typeName))
        .forEach((prototype) => {
            const currentModel = models[prototype.typeName as keyof { lead: Lead; agency: Agency; agent: Agent }]

            view[prototype.typeName] = prototype.attributes
                .filter(({ isHidden }) => !isHidden)
                .reduce((acc: any, current) => {
                    const name = current.name as keyof typeof currentModel

                    if ((current?.type === "string" || current?.type === "number") && current.name) {
                        return { ...acc, [current.name]: currentModel?.[name] || current.displayName }
                    }

                    if (current.name === "phone") {
                        return {
                            ...acc,
                            [current.name]: (currentModel as Lead)?.phones?.[0]?.number || current.displayName,
                        }
                    }

                    if (current.name === "phoneNumber") {
                        return {
                            ...acc,
                            [current.name]: (currentModel as Agent)?.phoneNumber?.number || current.displayName,
                        }
                    }

                    if (current.name === "agencyPhoneNumber") {
                        return {
                            ...acc,
                            [current.name]: (currentModel as Agency)?.agencyPhoneNumber?.number || current.displayName,
                        }
                    }

                    if (current.name === "agencyOutboundPhoneNumber") {
                        return {
                            ...acc,
                            [current.name]:
                                (currentModel as Agency)?.agencyOutboundPhoneNumber?.number || current.displayName,
                        }
                    }

                    return acc
                }, {})
        })

    if (signature) {
        view.templateRenderedSignature = Mustache.render(signature, view)
    }

    if (theme) {
        const { localBody: parsedBody, localView: parsedView } = mustacheParseMessagBody(body, view)
        parsedView.templateRenderedBody = Mustache.render(parsedBody, parsedView)
        return Mustache.render(theme, parsedView)
    }

    const { localBody: parsedBody, localView: parsedView } = mustacheParseMessagBody(body, view)
    return Mustache.render(parsedBody, parsedView)
}

function mustacheParseMessagBody(body: string, view: { [key: string]: any }) {
    let localBody = body
    const localView = structuredClone(view)

    Mustache.parse(localBody)
        .filter((bodyPart) => bodyPart[0] === "name")
        .forEach((bodyPart) => {
            const elem = bodyPart[1].toString()

            const [property, fallback] = elem.split(":")

            if (!fallback) {
                return
            }

            const fallbackValue = fallback.replace(/[^a-zA-Z0-9. ]/g, "")
            const typeName = property.split(".")[0]
            const attributeName = property.split(".")[1]

            if (camelCaseToWords(localView[typeName][attributeName]) === attributeName) {
                localView[typeName][attributeName] = fallbackValue
            }
            localBody = localBody.replace(`:${fallback}`, "")
        })

    return { localBody, localView }
}

export function camelCaseToWords(str?: string) {
    if (!str) return ""
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, "")
}

export function isAgencyTheme(elem: Theme): elem is AgencyTheme {
    return (elem as AgencyTheme).displayName === "Agency"
}

export function isThemeColor(elem: Theme): elem is ThemeColors {
    return (elem as ThemeColors).linkColor !== undefined
}

export function isMessageTheme(elem: Theme): elem is MessageTheme {
    return (elem as MessageTheme).body !== undefined
}

export function unsecuredCopyToClipboard(text: string) {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
        document.execCommand("copy")
    } catch (err) {
        console.error("Unable to copy to clipboard", err)
    }
    document.body.removeChild(textArea)
}

export function copyToClipboard(text: string) {
    if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(text)
    } else {
        unsecuredCopyToClipboard(text)
    }
}

export function getDomainId() {
    return (
        localStorage.getItem("leadDomainId") ||
        localStorage.getItem("taskDomainId") ||
        JSON.parse(getCookieValue("selectedDomain") || "{}")?._id
    )
}

export function getAgencyId() {
    return JSON.parse(getCookieValue("selectedAgency") || "{}")?._id
}

export function parseJwt(token: string) {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
    )

    return JSON.parse(jsonPayload)
}

export function convertAddressToString(address: Address) {
    return `<p>${address?.line1} <br> ${address?.city}, ${address?.state} ${address.zipcode} ${address.country}</p>`
}

export function formatNumberToMinutesAndSeconds(number: number) {
    const minutes = Math.floor(number / 60) > 9 ? Math.floor(number / 60) : `0${Math.floor(number / 60)}`
    const seconds = number % 60 > 9 ? number % 60 : `0${number % 60}`
    return `${minutes}:${seconds}`
}

export function getTaskTypes() {
    return [
        { attribute: "call", value: "Phone Call" },
        { attribute: "email", value: "Email" },
        { attribute: "sms", value: "Text Message" },
        { attribute: "todo", value: "To-do" },
    ]
}

export function getRoleOptions() {
    return [
        { label: "Owner", value: "owner" },
        { label: "Member", value: "member" },
    ]
}

export function getTaskReminderOptions() {
    return [
        { attribute: "minutes_30", value: "30 min before" },
        { attribute: "hours_1", value: "1 hour before" },
        { attribute: "days_1", value: "1 day before" },
        { attribute: "weeks_1", value: "1 week before" },
    ]
}

export function isImageType(mimeType: string) {
    return ["image/jpeg", "image/png"].includes(mimeType)
}

export function isVideoType(mimeType: string) {
    return ["video/mp4", "video/mov"].includes(mimeType)
}

export function getCookieValue(name: string) {
    const cookie = {} as { [key: string]: string }
    document.cookie.split(";").forEach((el) => {
        const [key, ...value] = el.split("=")
        const formattedValue = value.join("=")
        cookie[key.trim()] = formattedValue
    })
    return cookie[name]
}

export function setCookie(name: string, value: string, isForDomain = false, hasExpiration = true) {
    let cookie = `${name}=${value}`

    if (!process.env.VUE_APP_BASE_WEB_URL) {
        return
    }

    if (hasExpiration) {
        const date = new Date()
        const cookieExpirationDate = new Date(date.setMonth(date.getMonth() + 1)).toUTCString()
        cookie += `;expires=${cookieExpirationDate}`
    }

    if (isForDomain) {
        const baseUrl = new URL(process.env.VUE_APP_BASE_WEB_URL)
        const domain = window.location.hostname === "localhost" ? "localhost" : `.${baseUrl.hostname}`
        cookie += `;domain=${domain}`
    }

    document.cookie = cookie
}

export function deleteAllCookies() {
    const cookiesArray = document.cookie.split(";")

    cookiesArray.forEach((cookie) => {
        if (!process.env.VUE_APP_BASE_WEB_URL) {
            return
        }
        const [key] = cookie.split("=")
        const baseUrl = new URL(process.env.VUE_APP_BASE_WEB_URL)
        const domain = window.location.hostname === "localhost" ? "localhost" : `.${baseUrl.hostname}`
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`
    })
}

export function deleteCookie(name: string) {
    if (!process.env.VUE_APP_BASE_WEB_URL) {
        return
    }
    const baseUrl = new URL(process.env.VUE_APP_BASE_WEB_URL)
    const domain = window.location.hostname === "localhost" ? "localhost" : `.${baseUrl.hostname}`
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`
}

export function handleSubdomainRedirect(agency: Partial<Agency> | undefined) {
    const domains = agency?.agencyDomains
    if (!process.env.VUE_APP_BASE_WEB_URL || window.location.hostname === "localhost" || !domains?.length) {
        return
    }

    setCookie("selectedAgency", JSON.stringify(agency), true)

    // If the user is already on the correct subdomain, set domain
    const currentSubDomain = window.location.hostname.split(".")?.[0]
    const findDomain = domains?.find((domain) => domain.url === currentSubDomain)
    if (findDomain) {
        setCookie("selectedDomain", JSON.stringify(findDomain), true)
        return
    }

    if (domains.length > 1) {
        setCookie("selectedDomain", JSON.stringify({ _id: "all", url: "All domains" }), true)
        return
    }

    setCookie("selectedDomain", JSON.stringify(domains[0]), true)

    const baseUrl = new URL(process.env.VUE_APP_BASE_WEB_URL)

    return window.location.replace(`${baseUrl.protocol}//${domains[0].url}.${baseUrl.hostname}${baseUrl.pathname}`)
}

export function isValidDomain(domain: string) {
    const domainPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/

    return domainPattern.test(domain)
}

export function linkify(text: string) {
    const urlRegex = /(\b(https?):((\/\/)|(?:&#x2F;){2})[-A-Z0-9+&@#/%?=~_|!:,.;]*(?:&#x2F;)?[-A-Z0-9+&@#/%=~_|])/gi
    if (text.includes("<a")) {
        // If the text already contains an anchor tag, just add the underline class to it
        return text.replace(/<a/g, '<a class="underline text-link-color-500 hover:text-link-color-700"')
    }
    return text.replace(
        urlRegex,
        (url) =>
            `<a href="${url}" class="underline text-link-color-500 hover:text-link-color-700" target="_blank" > ${url} </a>`
    )
}

export function convertValue(value: string, isUsedForDisplay?: boolean): string {
    const valueMap: Record<string, string> = {
        call: "Phone Call",
        email: "Email",
        todo: "To-do",
        sms: "Text Message",
        "messaging-template": "Messaging Template",
        "contact-stage": "Contact Stage",
        minutes_30: "30 Minutes Before",
        hours_1: "1 Hour Before",
        days_1: "1 Day Before",
        weeks_1: "1 Week Before",
        unpublished: "Unpublished",
        off: "Off",
        on: "On",
    }

    return valueMap[value] || (isUsedForDisplay ? value : capitalize(value))
}

export function convertType(type?: string) {
    if (type === "Phone Call") return "call"
    if (type === "Email") return "email"
    if (type === "To-do") return "todo"
    if (type === "Text Message") return "sms"

    // types for tags
    if (type === "Messaging Template") return "messaging-template"
    if (type === "Contact Stage") return "contact-stage"

    return type?.toLowerCase()
}

export function convertTriggerEvent(event: string) {
    if (event === ContactActivityTriggerNodeType.callStarted) return "Call Started"
    if (event === ContactActivityTriggerNodeType.callEnded) return "Call Ended"
    if (event === ContactActivityTriggerNodeType.propertyValueChanged) return "Property Value Changed"
    if (event === ContactActivityTriggerNodeType.membershipChanged) return "Membership Changed"
    if (event === ContactActivityTriggerNodeType.enrolledInWorkflow) return "Enrolled in Workflow"
    if (event === ContactActivityTriggerNodeType.unenrollFromWorkflow) return "Unenrolled from Workflow"
    if (event === ContactActivityTriggerNodeType.leadCreated) return "Lead created"

    return capitalize(event)
}

export function displayVisibility(value: string) {
    return value === "false" ? "Visible" : "Hidden"
}

export function displayFilterValue(
    value: string | number,
    isDate?: boolean,
    shouldDisplayTime?: boolean,
    isType?: boolean,
    isUsedForDisplay?: boolean,
    isReminder?: boolean
) {
    if (typeof value === "number") {
        return value
    }

    if (isType || isReminder) {
        return convertValue(value || "")
    }

    if (isDate) {
        if (!value) return ""
        return formatHumanReadableDate(new Date(value), shouldDisplayTime || false)
    }

    return isUsedForDisplay ? capitalize(value?.replace(/\[,]/g, "")) : value?.replace(/\[,]/g, "")
}

export function displayFilterRule(value?: string) {
    return value
        ?.replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase()
}

export function isValidEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(email)
}

export function isValidPhoneNumber(phoneNumber: string) {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/
    return phoneNumberPattern.test(phoneNumber)
}

export function cn(defaultClasses: string, customClasses?: string) {
    return twMerge(defaultClasses, customClasses)
}

export function getNotFoundText(resource: string, searchTerm?: string) {
    if (!searchTerm) {
        return `No ${resource} found`
    }
    return `No ${resource} match "${searchTerm}"`
}

export function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function getSendTypeForWorkflow(type: string) {
    switch (type) {
        case SendEmailToType.enrolled:
            return "Enrolled contacts"
        case SendEmailToType.associated:
            return "Associated contact"
        case SendEmailToType.specificEmail:
            return "Specific email"
        case SendEmailToType.specificUsers:
            return "Specific users"
    }
}

export const dateTimeConverter = (time: string, date?: string) => {
    const timeArray = time ? time.split(":") : [10, 0]
    const dateObject = date ? new Date(date) : new Date()
    dateObject.setHours(Number(timeArray[0]))
    dateObject.setMinutes(Number(timeArray[1]))
    dateObject.setSeconds(0)
    return dateObject.toISOString() ?? ""
}

export function checkIfQueueTimeIsInPast(dateValue: string, timeValue: string) {
    const date = new Date(dateValue)
    const hours = Number(timeValue.split(":")[0])
    const minutes = Number(timeValue.split(":")[1])
    const queueDateAndTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)
    return queueDateAndTime < new Date()
}
